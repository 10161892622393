import axios from '@axios'

export default {
  namespaced: true,
  state: {
    certificate: {},
    blog: {},

  },
  getters: {},
  mutations: {

    GET_CERTIFICATE(state, payload) {
      state.certificate = payload
    },
    GET_BLOG(state, payload) {
      state.blog = payload
    },
  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addBlog(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('posts', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getAllNews(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('news', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updateblog(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`posts/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addcertificate(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('certificates', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    allcertificate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('certificates', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    Deletecertificate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`certificates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateaCertificate(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`certificates/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    DeleteSelectedCertificate(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('certificates-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllBlogList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('posts-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllBlog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('posts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllBlogSEO(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('post-seo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    DeleteBlog(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`posts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getblog(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`posts/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    GetCertificate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`certificates/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getCoursesByCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`courses-by-category?category_id=${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    GetAllMail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('mails', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },

}
