import axios from '@axios'

export default {
  namespaced: true,
  state: {
    user: {},

  },
  getters: {},
  terms: {},
  mutations: {

    GET_USER(state, payload) {
      console.log('payload', payload)
      state.user = payload
      console.log('state.category', state.user)
    },
    SET_TERMS(state, payload) {
      console.log('payload', payload)
      state.terms = payload
      console.log('state.terms', state.terms)
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('users', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addGenerateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('code-generator', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    HomeSection(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('home-sections', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    copyProtection(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('copy-protection', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GetHomeSection(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('home-sections')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GetcopyProtectiondata(ctx) {
      console.log('popo')

      return new Promise((resolve, reject) => {
        axios
          .get('copy-protection')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getConslutingService(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('consulting-service')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getBecomeInstructor(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('become-instructor')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addBecomeInstructor(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('become-instructor', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addConslutingService(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('consulting-service', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GetSettingArray(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('settings-array', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateSettingArray(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('settings-array-update', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getAccerditedPage() {
      return new Promise((resolve, reject) => {
        axios
          .get('accerdited-page')
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    addAccerditedPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('accerdited-page', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    GenerateDynaimcMap(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-dynamic-site-map', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    allCodeGenertor(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('code-generator')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addDuration(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('durations', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getDuration(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('durations', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GenerateMap(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-site-map')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteGenerateCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`code-generator/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`users/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteDuration(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`durations/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`users/${id}`)
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addLinkRederication(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('redirections', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addLinkRedericationArabic(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('redirection-to-arabics', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getAllRediraction(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('redirections', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllRediractionArabic(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('redirection-to-arabics', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteLink(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`redirections/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQualityPolice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('quality-policy', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getQualityPolice(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('quality-policy')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePrivacyPolice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('privacy-policy', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPrivacyPolice(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('privacy-policy')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllRoles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('roles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getTerms(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('terms')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFOOTER(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('terms')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTerms(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('terms', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateInfo(ctx, payload) {
      console.log('payload', payload)

      return new Promise((resolve, reject) => {
        axios
          .post('update-profile', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getInfo() {
      return new Promise((resolve, reject) => {
        axios
          .get('update-profile')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getStatsitic() {
      return new Promise((resolve, reject) => {
        axios
          .get('get-general-counts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdatePassowrd(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('change-password', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
