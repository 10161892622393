export default [
  {
    path: '/prices/',
      name: 'index-price',
      component: () => import('@/views/prices/index.vue'),
    children: [
    {
      path: '/classical-course-price',
      name: 'classical-course-price',
      component: () => import('@/views/prices/ClassicalCoursePrices.vue'),
      meta:{
        resource: 'price',
        action:'view'
      }
    },
   
    {
      path: '/code-generator',
      name: 'code-generator',
      component: () => import('@/views/setting/CodeGenerator.vue'),
      meta:{
        resource: 'price',
        action:'view'
      }
    },
    {
      path: '/index-specific-price',
      name: 'index-specific-price',
      component: () => import('@/views/prices/specific-price/index.vue'),

      meta:{
        resource: 'price',
        action:'view'
      },
      children:[
        
    {
      path: '/update-specific-price/:id',
      name: 'update-specife-price',
      component: () => import('@/views/prices/specific-price/UpdateSpecifePrice.vue'),

      meta:{
        resource: 'price',
        action:'view'
      }
    },
    {
      path: '/add-specific-price',
      name: 'add-specife-price',
      component: () => import('@/views/prices/specific-price/AddSpecificPrice.vue'),

      meta:{
        resource: 'price',
        action:'view'
      }
    },
  
    {
      path: '/specific-price',
      name: 'specific-price',
      component: () => import('@/views/prices/specific-price/ViewSpecificPrice.vue'),

      meta:{
        resource: 'price',
        action:'view'
      }
    },
  ]},
    {
      path: '/online-course-price',
      name: 'online-course-price',
      component: () => import('@/views/prices/OnlineCoursePrice.vue'),
      meta:{
        resource: 'price',
        action:'view'
      }
    },
    {
      path: '/all-prices',
      name: 'all-prices',
      component: () => import('@/views/prices/ViewPrice.vue'),
      meta:{
        resource: 'price',
        action:'view'
      }
    },
    {
      path: '/update-price/:id',
      name: 'update-price',
      component: () => import('@/views/prices/UpdatePrice.vue'),
      meta:{
        resource: 'price',
        action:'view'
      }
    },
    
  ]
}]