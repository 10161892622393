export default [
  {
    path: '/seo-index',
    name: 'seo-index',
    component: () => import('@/views/seo/index.vue'),
    meta:{
      resource: 'seo',
      action:'read'
    },
    children: [  {
      path: '/seo-pages',
      name: 'seo-pages',
      component: () => import('@/views/seo/SeoPages.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
      // }
    },
    {
      path: '/seo-blog',
      name: 'seo-blog',
      component: () => import('@/views/seo/seoblog/BlogSeo.vue'),
      // meta:{src\views\seo\seoblog
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
      // }
    },
    {
      path: '/update-seo-pages/:id',
      name: 'update-seo-pages',
      component: () => import('@/views/seo/UpdateSeoPages.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
    },

        {
      path: '/seo-course',
      name: 'seo-course',
      component: () => import('@/views/seo/seocourse/CourseSeo.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
      children: [
    {
        path: '/seo-online-course',
        name: 'seo-online-course',
        component: () => import('@/views/seo/seocourse/SeoOnlineCourse.vue'),
        // meta:{
        //   resource: 'all',
        //   action:'view'
        // }
      },
    {
      path: '/seo-classical-course',
      name: 'seo-classical-course',
      component: () => import('@/views/seo/seocourse/SeoClassicalCourse.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
    },
    {
      path: '/seo-course-in-city',
      name: 'seo-course-in-city',
      component: () => import('@/views/seo/seocourse/SeoCourseIncity.vue'),
      meta:{
        resource: 'all',
        action:'view'
      },
      
    },
  ]},
  
    {
      path: '/seo-category',
      name: 'seo-category',
      component: () => import('@/views/seo/seocategory/CategorySeo.vue'),
     children:[
    {
      path: '/seo-classical-category',
      name: 'seo-classical-category',
      component: () => import('@/views/seo/seocategory/SeoClassicCategory.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
    },
    {
      path: '/seo-online-category',
      name: 'seo-online-category',
      component: () => import('@/views/seo/seocategory/SeoOnlineCategory.vue'),
      
    },
    
  ]},
  {
    path: '/seo-category-in-city',
    name: 'seo-category-in-city',
    component: () => import('@/views/seo/SeoCategryInCity.vue'),
    // meta:{
    //   resource: 'all',
    //   action:'view'
    // }
  },
    {
      path: '/seo-home-page',
      name: 'seo-home-page',
      component: () => import('@/views/seo/SeoHomePage.vue'),
      
    },
    {
      path: '/seo-about-page',
      name: 'seo-about-page',
      component: () => import('@/views/seo/SeoAboutUs.vue'),
      
    },
    {
      path: '/seo-city',
      name: 'seo-city',
      component: () => import('@/views/seo/SeoCity.vue'),
      
    },
    {
      path: '/seo-cities',
      name: 'seo-cities',
      component: () => import('@/views/seo/SeoCitiesPage.vue'),
      
    },
    {
      path: '/seo-categories-page',
      name: 'seo-categories-page',
      component: () => import('@/views/seo/SeoCategoriesPage.vue'),
      
    },
    {
      path: '/seo-become-instructor',
      name: 'seo-become-instructor',
      component: () => import('@/views/seo/SeoBecomeInstructor.vue'),
      
    },
    {
      path: '/in_house_training',
      name: 'in_house_training',
      component: () => import('@/views/seo/SeoInTraningPage.vue'),
      
    },
   
   
   
   
  ]
}]