import axios from '@axios'

export default {
  namespaced: true,
  state: {
    slider:{}
   
  },
  getters: {},
  mutations: {
   
    GET_SLIDER(state,payload) {
      console.log("payload",payload)
      state.slider = payload
      console.log("state.category",state.slider)
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addSlider(ctx, payload) {
 
        return new Promise((resolve, reject) => {
          axios
            .post('slides', payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
      },
    
 
      AllSlider(ctx, queryParams) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('slides', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      UpdateSlider(ctx,payload) {
      
        return new Promise((resolve, reject) => {
          axios
            .post(`slides/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteSlider(ctx, id) {
        console.log("DDcdb")
        console.log("payload",id)
        return new Promise((resolve, reject) => {
          axios
            .delete(`slides/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetSlider(ctx,{id} ) {
    
  
        return new Promise((resolve, reject) => {
          axios
            .get(`slides/${id}`)
            .then(response => {
              console.log(response?.data.data)
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },

 
  getAllOnlineCourse(ctx, ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('courses?filter[online] = 1')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},

}
