import axios from '@axios'

export default {
  namespaced: true,
  state: {
    instructors: {},

  },
  getters: {},
  mutations: {

    GET_INSTRUCTOR(state, payload) {
      console.log('payload', payload)
      state.instructors = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // instructor Actions
    // ------------------------------------------------
    addinstructor(ctx, payload) {
      console.log('DDcdb')
      console.log('payload', payload)
      return new Promise((resolve, reject) => {
        axios
          .post('instructors', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updateinstructor(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`instructors/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSelected(ctx, payload) {
      console.log('DDcdb')
      console.log('payload', payload)
      return new Promise((resolve, reject) => {
        axios
          .post('instructors-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteInstructor(ctx, id) {
      console.log('DDcdb')
      console.log('payload', id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`instructors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getinstructor(ctx, { id }) {
      console.log(id)

      return new Promise((resolve, reject) => {
        axios
          .get(`instructors/${id}`)
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllinstructor(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('instructors', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getAllinstructorsClassical(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('instructors?filter[online] = 0')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
