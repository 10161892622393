export default [
    {
      path: '/user-profile',
      name: 'user-profile',
      component: () => import('@/views/setting/UserProfile.vue'),
      meta:{
        resource: 'setting',
        action:'read'
      }
    },
    {
      path: '/setting',
      name: 'setting-index',
      component: () => import('@/views/setting/index.vue'),
      children:[
   
    
    {
      path: '/links-redirection',
      name: 'links-redirection',
      component: () => import('@/views/setting/LinkRedirication.vue'),
      meta:{
          resource: 'setting',
        action:'read'
      }
    },
     
    {
      path: '/links-redirection-arabic',
      name: 'links-redirection-arabic',
      component: () => import('@/views/setting/LinkRediractionArabic.vue'),
      meta:{
          resource: 'setting',
        action:'read'
      }
    },
    {
        path: '/courses-durations',
        name: 'courses-durations',
        component: () => import('@/views/setting/CoursesDuration.vue'),
        meta:{
           resource: 'setting',
          action:'read'
        }
      },
      {
        path: '/analytic-codes',
        name: 'analytic-codes',
        component: () => import('@/views/setting/AnalyticCodes.vue'),
        meta:{
          resource: 'setting',
          action:'read'
        }
      },
      {
        path: '/zoho-integration',
        name: 'zoho-integration',
        component: () => import('@/views/setting/ZOHOIntegration.vue'),
        meta:{
          resource: 'setting',
          action:'read'
        }
      },
      {
        path: '/home-page-section',
        name: 'home-page-section',
        component: () => import('@/views/setting/HomePageSections.vue'),
        meta:{
          resource: 'setting',
          action:'read'
        }
      },
      {
        path: '/copying-protection',
        name: 'copying-protection',
        component: () => import('@/views/setting/CopyProtection.vue'),
        meta:{
           resource: 'setting',
          action:'read'
        }
      },
      {
        path: '/optimize-website',
        name: 'optimize-website',
        component: () => import('@/views/setting/OptimizeWebsite.vue'),
        meta:{
           resource: 'setting',
          action:'read'
        }
      },
     
      {
        path: '/user',
        name: 'index-user',
        component: () => import('@/views/setting/usermanagemnet/index.vue'),
       
      children:[
        
      {
          path: '/add-user',
          name: 'add-user',
          component: () => import('@/views/setting/usermanagemnet/AddUser.vue'),
          meta:{
             resource: 'setting',
            action:'read'
          }
        },
        {
          path: '/all-user-tab',
          name: 'all-user-tab',
          component: () => import('@/views/setting/usermanagemnet/ViewUsers.vue'),
          meta:{
             resource: 'setting',
            action:'read'
          }
         
        },
        {
          path: '/update-user/:id',
          name: 'update-user',
          component: () => import('@/views/setting/usermanagemnet/UpdateUser.vue'),
          meta:{
             resource: 'setting',
            action:'read'
          }
        },
      ]}
      
    ]}, 
    
    
    
  ]
  