export default [
  {
    path: "",
    // redirect: "/courses",
    name: 'index-other-course',
    component: () => import('@/views/othercourses/index.vue'),
    children: [

    {
      path: '/other-course/add-other-course',
      name: 'add-other-course',
      component: () => import('@/views/othercourses/AddCourse.vue'),
      meta:{
        resource: 'other_course',
        action:'view'
      }
    },
   
    {
      path: '/other-course/update-other-course/:id',
      name: 'update-other-courses',
      component: () => import('@/views/othercourses/UpdateCourse.vue'),
      meta:{
        resource: 'other_course',
        action:'view'
      }
    },
    
    {
        path: '/other-course/schedual-deticatedd-courses',
        name: 'schedual-deticatedd-courses',
        component: () => import('@/views/othercourses/SchedualDeticatedCourses.vue'),
        meta:{
         resource: 'other_course',
          action:'view'
        }
      },
       
    {
      path: '/other-course/deticated-schedual-courses-list',
      name: 'deticated-schedual-courses-list',
      component: () => import('@/views/othercourses/OtherScheduleList.vue'),
      meta:{
       resource: 'other_course',
        action:'view'
      }
    },
    {
      path: '/other-course/deticated-courses',
      name: 'deticated-courses',
      component: () => import('@/views/othercourses/DeticatedCourses.vue'),
      meta:{
        resource: 'other_course',
        action:'view'
      }
    },
    {
      path: '/other-course/content',
      name: 'Deticated-courses-content',
      component: () => import('@/views/othercourses/DeticatedCourseContent.vue'),
      meta:{
        resource: 'other_course',
        action:'view'
      }
    },
    
    {
        path: '/other-course/seo-detcated-course',
        name: 'seo-detcated-course',
        component: () => import('@/views/othercourses/SeoDetcatedCourse.vue'),
        meta:{
          resource: 'other_course',
          action:'view'
        }
      }

  ]
}]
  