export default [
  {
    path: "/courses",
    // redirect: "/courses",
       name: 'index-course',
    component: () => import('@/views/courses/index.vue'),
    children: [
    {
      path: '/add-course',
      name: 'add-course',
      component: () => import('@/views/courses/AddCourse.vue'),
      meta:{
        resource: 'course',
        action:'view'
      }
    },
    // {
    //   path: '/courses',
    //   name: 'index-course',
    //   component: () => import('@/views/courses/index.vue'),
    //   // meta:{
    //   //   resource: 'all',
    //   //   action:'view'
    //   // }
    // },
    {
      path: '/update-course/:id',
      name: 'update-courses',
      component: () => import('@/views/courses/UpdateCourses.vue'),
      meta:{
        resource: 'course',
        action:'view'
      }
    },
    {
      path: '/classical-courses',
      name: 'classical-courses',
      component: () => import('@/views/courses/ClassicalCourses.vue'),
      meta:{
        resource: 'course',
        action:'view'
      }
    },
    {
      path: '/generate-course',
      name: 'generate-course',
      component: () => import('@/views/courses/generateOnlineCourse.vue'),
      meta:{
        resource: 'course',
        action:'view'
      }
    },
    {
      path: '/online-courses',
      name: 'online-courses',
      component: () => import('@/views/courses/OnlineCourses.vue'),
      meta:{
        resource: 'course',
        action:'view'
      }
    }
  ]
}]
  