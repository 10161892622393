import axios from '@axios'

export default {
  namespaced: true,
  state: {
    seoPage: {},

  },
  getters: {},
  mutations: {

    GET_SEO_PAGES(state, payload) {
      console.log('payload', payload)
      state.seoPage = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addSeoCourseClassic(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('seo-course', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`dynamic-pages/${id}`)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updatePage(ctx, payload) {
      return new Promise((resolve, reject) => {

        axios
          .post(`dynamic-pages/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getAllDynamicPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('dynamic-pages', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postSeo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('post-seo', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetpostSeo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('post-seo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCourseInCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('seo-course-city', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSeoCategory(ctx, payload) {
      console.log('DDcdb')
      console.log('payload', payload)
      return new Promise((resolve, reject) => {
        axios
          .post('seo-category', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategoryinCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('category-city-content', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCityContent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('city-content', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSeoCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('seo-city', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategryInCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('seo-category-city', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCity(ctx, payload) {
      console.log('DDcdb')
      console.log('payload', payload)
      return new Promise((resolve, reject) => {
        axios
          .post(`cities/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateSeoPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`seo-pages/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSeoCategory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`seo-category/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCategorySeo(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-category/${id}&filter[online]=0`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetSeoCity(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-city/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCategoryInCity(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-category-city/${payload.categoryid}/${payload.city}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCourseInCity(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-course-city/${payload.courseid}/${payload.city}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCategorySeoOnline(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-category/${id}&filter[online]=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetSeoCourse(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-course/${id}&filter[online]=0`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetSeoCourseOnline(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-course/${id}&filter[online]=1`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePostSeo(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`post-seo/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSeoCategoryInCity(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`seo-category-city/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getAllCoursesSEO(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-course-classic', { params: queryParams })
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllCoursesSEOOnline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-course-online', { params: queryParams })
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllSeoPages(ctx, { id }) {
      console.log(id)

      return new Promise((resolve, reject) => {
        axios
          .get('seo-pages')
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllCAtegoryEO(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-category', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    SeonInCity(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-course-city', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    GetSeoPage(ctx, { id }) {
      console.log('seo-pages', id)

      return new Promise((resolve, reject) => {
        axios
          .get(`seo-pages/${id}`)
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getAllCategoryInCity(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-category-city', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesonline(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses?filter[online] = 1')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteClassicCourse(ctx, id) {
      console.log(id)

      return new Promise((resolve, reject) => {
        axios
          .delete(`seo-course/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
