export default [
  {
    path: '/articales',
    name: 'index-article',
    component: () => import('@/views/blog/index.vue'),
    // meta:{
    //   resource: 'all',
    //   action:'view'
    // }
    children: [
    {

      path: '/add-article',
      name: 'add-article',
      component: () => import('@/views/blog/AddArticale.vue'),
      meta:{
        resource: 'post',
        action:'view'
      },
    },
    {

      path: '/update-article/:id',
      name: 'update-article',
      component: () => import('@/views/blog/UpdateArticle.vue'),
      meta:{
        resource: 'post',
        action:'view'
      },
    },
  
    
    {
      path: '/all-articales',
      name: 'all-articales',
      component: () => import('@/views/blog/AllArticales.vue'),
      meta:{
        resource: 'post',
        action:'view'
      }
    },
    
    {
      path: '/all-news',
      name: 'all-news',
      component: () => import('@/views/blog/AllNews.vue'),
      meta:{
        resource: 'post',
        action:'view'
      }
    },
    
  ]
},
{
  path: '/career',
  name: 'index-career',
  component: () => import('@/views/career/index.vue'),
  // meta:{
  //   resource: 'all',
  //   action:'view'
  // }
  children: [
  {

    path: '/add-career',
    name: 'add-career',
    component: () => import('@/views/career/AddCareer.vue'),
    meta:{
      resource: 'career',
      action:'view'
    },
  },
  {

    path: '/update-career/:id',
    name: 'update-career',
    component: () => import('@/views/career/UpdateCareer.vue'),
    meta:{
      resource: 'career',
      action:'view'
    },
  },

  
  {
    path: '/all-career',
    name: 'all-career',
    component: () => import('@/views/career/AllCareer.vue'),
    meta:{
      resource: 'career',
      action:'view'
    }
  },
  
]
}]