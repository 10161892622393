import axios from '@axios'

export default {
  namespaced: true,
  state: {
    certificate: {},
    blog: {},

  },
  getters: {},
  mutations: {

    GET_CERTIFICATE(state, payload) {
      state.certificate = payload
    },
    GET_BLOG(state, payload) {
      state.blog = payload
    },
  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCareer(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('position', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    UpdateCareer(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`position/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCareer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`position/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    allPosition(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('position', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCareer(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`position/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },

}
