export default [
  {
    path: '/client',
    name: 'index-client',
    component: () => import('@/views/clients/index.vue'),
    
    children: [
    {
      path: '/add-client',
      name: 'add-client',
      component: () => import('@/views/clients/AddClient.vue'),
      meta:{
        resource: 'client',
        action:'view'
      },
    },
   
    
    {
      path: '/update-client/:id',
      name: 'update-client',
      component: () => import('@/views/clients/UpdateClient.vue'),
      meta:{
        resource: 'client',
        action:'view'
      },
    },
    {
      path: '/all-client',
      name: 'all-client',
      component: () => import('@/views/clients/AllClients.vue'),
      meta:{
        resource: 'client',
        action:'view'
      }
    },]},
    {
      path: '/mail/',
      name: 'index-mails',
      component: () => import('@/views/mail/index.vue'),
      meta:{
        resource: 'mail',
        action:'view'
      }
    },
    {
      path: '/accreditations/',
      name: 'index-accreditation',
      component: () => import('@/views/accreditations/index.vue'),
      meta:{
        resource: 'accreditation',
        action:'view'
      },
      children: [
    {
        path: '/add-accreditation',
        name: 'add-accreditation',
        component: () => import('@/views/accreditations/AddAccreditation.vue'),
        meta:{
          resource: 'accreditation',
          action:'view'
        },
      },
      
      {
        path: '/update-accreditations/:id',
        name: 'update-accreditation',
        component: () => import('@/views/accreditations/UpdateAccreditation.vue'),
        meta:{
          resource: 'accreditation',
          action:'view'
        },
      },
      
      {
        path: '/all-accreditations',
        name: 'all-accreditations',
        component: () => import('@/views/accreditations/AllAccreditation.vue'),
        meta:{
          resource: 'accreditation',
          action:'view'
        },
      },
    ]},
      {
        path: '/mail/all-mails',
        name: 'all-mails',
        component: () => import('@/views/mail/AllMails.vue'),
        meta:{
          resource: 'mail',
          action:'view'
        }
      },
    
  ]
  