import axios from '@axios'

export default {
  namespaced: true,
  state: {
    Price :{},
    SpecifePrice:{}
   
  },
  getters: {},
  mutations: {
    
    GET_PRICE(state,payload) {
     
      state.Price = payload
     
    },
    GET_SPECIFE_PRICE(state, payload) {
      state.SpecifePrice = payload
   
 
      if (payload.by_rate == true) {
        state.SpecifePrice.by_rate = 1
      } else {
        state.SpecifePrice.by_rate = 0
      }
      if (payload.add == true) {
        state.SpecifePrice.add = 1
      } else {
        state.SpecifePrice.add = 0
      }
      state.SpecifePrice.country = payload.country.id
      state.SpecifePrice.city = payload.city.id
      state.SpecifePrice.category = payload.category.id
      state.SpecifePrice.course = payload.course.id
      // state.SpecifePrice.end_date = "2022-08-09"
    },
   
  },
  actions: {
  

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addPrice(ctx, payload) {
      
       
        return new Promise((resolve, reject) => {
          axios
            .post('prices',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addSpecificPrice(ctx, payload) {
       
        return new Promise((resolve, reject) => {
          axios
            .post('specific-prices',  payload ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      UpdateSpecificPrice(ctx, payload) {
      
       
        return new Promise((resolve, reject) => {
          axios
            .post(`specific-prices/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      updateChange(ctx, payload) {
    
        return new Promise((resolve, reject) => {
          axios
            .post('apply-specific-price',  payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      UpdatePrice(ctx, payload) {
      
       
        return new Promise((resolve, reject) => {
          axios
            .post(`prices/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteSelected(ctx, payload) {
      
       
        return new Promise((resolve, reject) => {
          axios
            .post('prices-bulk-delete',payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeletePrice(ctx, id) {
      
     
        return new Promise((resolve, reject) => {
          axios
            .delete(`prices/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      DeleteSpecificPrice(ctx, id) {
       
        return new Promise((resolve, reject) => {
          axios
            .delete(`specific-prices/${id}` )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      GetPrice(ctx,{id} ) {
      
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`prices/${id}`)
            .then(response => {
            
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      GetSpecifePrice(ctx,{id} ) {
      
        
  
        return new Promise((resolve, reject) => {
          axios
            .get(`specific-prices/${id}`)
            .then(response => {
            
             
              resolve(response)
            }
              )
            .catch(error => reject(error))
        })
      },
      getAllSpecificPrice(ctx,queryParams ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('specific-prices', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      getVistior(ctx ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('countries')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

      getAllPrice(ctx,queryParams ) {
  
        return new Promise((resolve, reject) => {
          axios
            .get('prices', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

  getAllPricesClassical(ctx, ) {
  
    return new Promise((resolve, reject) => {
      axios
        .get('Prices?filter[online] = 0')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
},

}
