import axios from '@axios'

export default {
  namespaced: true,
  state: {
    client: {},

  },
  getters: {},
  mutations: {

    GET_CLIENT(state, payload) {
      state.client = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addClient(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('clients', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    AllClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('clients', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('clients-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateClient(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`clients/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteClient(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`clients/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getAllOnlineCourse(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses?filter[online] = 1')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
