import axios from '@axios'

export default {
  namespaced: true,
  state: {
    image: {},

  },
  getters: {},
  mutations: {

    GET_IMAGE(state, payload) {
      console.log('payload', payload)
      state.image = payload
      console.log('state.category', state.image)
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addGallery(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('gallery-images', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addVideo(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('gallery-video', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('gallery-images-update-orders', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    AllVideo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('gallery-video', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    Allimages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('gallery-images', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Updateimage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`gallery-images/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeletImg(ctx, id) {
      console.log('DDcdb')
      console.log('payload', id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`gallery-images/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeletVideo(ctx, id) {
      console.log('DDcdb')
      console.log('payload', id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`gallery-video/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getimage(ctx, { id }) {
      console.log('GetCategory', id)

      return new Promise((resolve, reject) => {
        axios
          .get(`gallery-images/${id}`)
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getAllOnlineCourse(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses?filter[online] = 1')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
