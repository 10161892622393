import axios from '@axios'

export default {
  namespaced: true,
  state: {
    courses: {},

  },
  getters: {},
  mutations: {

    GET_COURSE(state, payload) {
      console.log('payload', payload)
      state.courses = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('other-courses', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDeticatedContent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('other-course-content', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    SeoOtherCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('seo-other-course', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addScheduleCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('other-schedules', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetSeoCourseContent(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .get(`seo-other-course/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`other-courses/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCourse(ctx, id) {
      console.log('DDcdb')
      console.log('payload', id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`other-courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSeoCotnent(ctx, id) {
      console.log('DDcdb')
      console.log('payload', id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`seo-other-course/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteOtherSchedule(ctx, id) {
      console.log('DDcdb')
      console.log('payload', id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`other-schedules/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCourse(ctx, { id }) {
      console.log(id)

      return new Promise((resolve, reject) => {
        axios
          .get(`other-courses/${id}`)
          .then(response => {
            console.log(response?.data.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    DeleteSelected(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('other-courses-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Allothercourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('other-courses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllothercoursesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('other-courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllOtherschedules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('other-schedules', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesSeoDeticated(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('seo-other-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AllCourses(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
