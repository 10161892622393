
// action types
export const  GET_ALL_COURSES = " GET_ALL_COURSES";
export const  LOGIN = "LOGIN";

export default {
  namespaced: true,
  state: {
  AllCourses:[]
  },
  
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    SET_ALL_COURSES(state, val){
       

    }
   
  },
  actions: {
    [LOGIN](context, credentials) {
    
      return new Promise(resolve => {
        this.$http.post("login" + credentials)
          .then(({ data }) => {
         
            resolve(data);
          })
          .catch(() => {
           
          });
      });
    },
   
    [GET_ALL_COURSES](context, credentials) {
      console,log("Ddd")
      return new Promise(resolve => {
        this.$http.post("auth/ForgotPassword?email=" + credentials.e_mail)
          .then(({ data }) => {
        
            resolve(data);
          })
          .catch(() => {
           
          });
      });
    },
    GET_ALL_COURSES (context) {
        context.commit('SET_ALL_COURSES')
      }
  },
}
