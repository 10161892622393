import axios from '@axios'

export default {
  namespaced: true,
  state: {
    courses: {},

  },
  getters: {},
  mutations: {

    GET_COURSE(state, payload) {
      state.courses = payload
    },

  },
  actions: {

    // ------------------------------------------------
    // Course Actions
    // ------------------------------------------------
    addCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('courses', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkLink(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('validate-link', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('generate-from-classical', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteGenerateCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`code-generator/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCourse(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`courses/${payload.id}`, payload.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCourse(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`courses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteSelectedCourses(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('courses-bulk-delete', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GetCourse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`courses/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getAllCoursesOnline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-online', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-classic', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getGenerateCourse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('generate-online-course', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCoursesClassical(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('courses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllCourses(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('related-courses-dropdown')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
