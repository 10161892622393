export default [
  {
    path: "/cities",
    name: 'index-city',
    component: () => import('@/views/citiesandlocation/index.vue'),
    children: [

    {
      path: '/add-city',
      name: 'add-city',
      component: () => import('@/views/citiesandlocation/AddCity.vue'),
      meta:{
        resource: 'city',
        action:'view'
      }
    },
   
    {
      path: '/update-city/:id',
      name: 'update-city',
      component: () => import('@/views/citiesandlocation/UpdateCity.vue'),
      meta:{
        resource: 'city',
        action:'view'
      }
    },
   
    {
      path: '/update-location/:id',
      name: 'update-location',
      component: () => import('@/views/citiesandlocation/UpdateLocation.vue'),
      meta:{
        resource: 'city',
        action:'view'
      }
    },
    {
      path: '/manage-location',
      name: 'manage-location',
      component: () => import('@/views/citiesandlocation/ManageLocation.vue'),
      meta:{
        resource: 'city',
        action:'view'
      }
    },
    {
      path: '/cities-list',
      name: 'cities-list',
      component: () => import('@/views/citiesandlocation/CitiesList.vue'),
      meta:{
        resource: 'city',
        action:'view'
      }
    },
    {
      path: '/locations-list',
      name: 'location-list',
      component: () => import('@/views/citiesandlocation/ViewLocation.vue'),
      meta:{
        resource: 'city',
        action:'view'
      }
    }
  ]
  }]
  