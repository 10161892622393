export default [
  {
    path: "/instructors",
    // redirect: "/courses",
    name: 'index-instructor',
    component: () => import('@/views/Instructor/index.vue'),
    children: [
    {
      path: '/create-instructor',
      name: 'add-instructor',
      component: () => import('@/views/Instructor/AddInstructor.vue'),
      meta:{
        resource: 'instructor',
        action:'view'
      }
    },
    
   
    {
      path: '/instructors-list',
      name: 'instructor-list',
      component: () => import('@/views/Instructor/InstructorList.vue'),
      meta:{
        resource: 'instructor',
        action:'view'
      }
    },
    {
      path: '/instructors/:id',
      name: 'update-instructor',
      component: () => import('@/views/Instructor/UpdateInstructor.vue'),
      meta:{
        resource: 'instructor',
        action:'view'
      }
    },
   
  ]
}]