export default [
   
    {
      path: '/Schedule/',
      name: 'schedule-index',
      component: () => import('@/views/Schedule/index.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    children:[
      {
        path: '/schedule-classical-course',
        name: 'schedule-classical-course',
        component: () => import('@/views/Schedule/ScheduleClassicCourse.vue'),
        meta:{
          resource: 'schedule',
          action:'view'
        },
      },
    {
      path: '/schedule-update/:id',
      name: 'schedule-update',
      component: () => import('@/views/Schedule/UpdateSchedule.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    },
    {
      path: '/schedule-online-course',
      name: 'schedule-online-course',
      component: () => import('@/views/Schedule/ScheduleOnlineCourse.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    },
    {
      path: '/all-Schedule',
      name: 'all-Schedule',
      component: () => import('@/views/Schedule/ViewSchedule.vue'),
      meta:{
        resource: 'schedule',
        action:'view'
      },
    },
  ]}
    
  ]
  