export default [
  {
    path: '/slider',
    name: 'index-slider',
    component: () => import('@/views/slider/index.vue'),
    meta:{
      resource: 'slider',
      action:'view'
    },
    children: [
    {
      path: '/add-slider',
      name: 'add-slider',
      component: () => import('@/views/slider/AddSlider.vue'),
      meta:{
        resource: 'slider',
        action:'view'
      },
    },
    
    
    {
      path: '/all-sliders',
      name: 'all-sliders',
      component: () => import('@/views/slider/ViewSliders.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
    },
    {
      path: '/update-slider/:id',
      name: 'update-slider',
      component: () => import('@/views/slider/UpdateSlider.vue'),
      // meta:{
      //   resource: 'all',
      //   action:'view'
      // }
    },
   
   
    
  ]
}]