import Vue from 'vue'
import VueRouter, { createRouter, createWebHashHistory } from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import dashboard from './routes/dashboard'

import pages from './routes/pages'
import footer from './routes/footer'
import Schedule from './routes/Schedule'
import seo from './routes/seo'
import prices from './routes/prices'
import content from './routes/content'
import homepage from './routes/homepage'
import citiesandlocation from './routes/citiesandlocation'
import courses from './routes/courses'
import othercourses from './routes/othercourses'
import certificates from './routes/certificates'
import categories from './routes/categories'
import slider from './routes/slider'
import setting from './routes/setting'
import instructor from './routes/instructor'
import AccreditationAndClients from './routes/AccreditationAndClients'
import blog from './routes/blog'
import { lineChartOptions } from '@/@core/components/statistics-cards/chartOptions'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',

  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },

    ...dashboard,
    ...pages,
    ...courses,
    ...categories,
    ...othercourses,
    ...instructor,
    ...citiesandlocation,
    ...prices,
    ...content,
    ...homepage,
    ...seo,
    ...certificates,
    ...slider,
    ...blog,
    ...AccreditationAndClients,
    ...setting,
    ...footer,
    ...Schedule,

    {
      path: '*',
      // redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const token = localStorage.getItem('token')
  const isLoggedIn = isUserLoggedIn()
  const IsSessionExperid = localStorage.getItem('IsSessionExperid')
  console.log(IsSessionExperid)

  if (!canNavigate(to)) {
    if (IsSessionExperid) {
       return next({ name: 'auth-login' })
      
    }
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
